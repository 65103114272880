import React from "react"

const Pricing = () => {
  return (
    <div className="row row_darkGray">
      <div className="col-8 offset-2 text-center">
        <h2>1/4…1/2…Whole Beef</h2>
        <p>
          Go in with a group or load up your freezer! Where’s the beef? In your
          deep freeze. (Price will be based upon hanging weight at Hudson Meats)
        </p>
        <h2>Pricing</h2>
        <p>$4.25 per pound carcass hang weight.</p>
      </div>
    </div>
  )
}

export default Pricing
